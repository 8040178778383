import React from "react";
import styled from "styled-components";
import Drawing from "./drawing";

const Container = styled.div`
    box-sizing: border-box;
    background: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    padding: 16px;
    top: 0;
    left: 0;
`;

const Link = styled.div`
    box-sizing: border-box;
    position: fixed;
    color: white;
    font-size: 24px;
    text-decoration: none;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 48px;
    font-family: williams-caslon-text, serif;
    a {
        color: white;
        text-decoration: underline;
        text-underline-position: under;
        white-space: nowrap;
    }
`;

function App() {
    return (
        <Container>
            <Drawing />
            <Link>
                <span role="img" aria-label="(Envelope)">
                    ✉️
                </span>
                <br />
                To start, send an introductory note to{" "}
                <a href="mailto:a-keyhole@darpa.press">
                    a-keyhole@darpa.press{" "}
                </a>
            </Link>
        </Container>
    );
}

export default App;
